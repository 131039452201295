import React from 'react';
import SearchContentByType from './search-content-by-type';
import HttpService from '../../../../services/rest/HttpService';
import { Properties, State } from './properties/search-content-by-type-container.properties';
import {
	contentStatisticsToOptions,
	extractOptionIds,
	relatedTypesMap,
	responseToOptions,
} from './helpers/search-content-by-type-container.helper';
import { optionToSearchContentModel } from '../../Sidebar/tags/subcomponents/content-tags/helpers/content-tags-container.helper';
import { analyticsService, featuresService, multiLingualService } from '../../../../App';
import { DebounceInput } from 'react-debounce-input';
import ModelMapper from '../../../../models/ModelMapper';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import SuggestedListOptions from './suggested-list-options.container';
import SearchOptionsContainer from './search-options-container';
import Loader from 'react-spinners/BeatLoader';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { ContentTypes } from '../../../../constants/content-types';
import { responseToPaginationModel } from '../../../../models/v2/Pagination/pagination.mapper';
import { Col, FormGroup, Label } from 'reactstrap';
import StatusSelect from '../../Sidebar/GeneralContentAttributes/subcomponents/status-select/status-select';
import { liveBlogStatusDropdownOptions } from '../../../Pages/Live Blog/components/subcomponents/status/status.helper';

class SearchContentByTypeContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			searchType: 'articles',
			options: [],
			inputValue: '',
			isLoading: false,
			currentPage: 1,
			pagination: PaginationMeta.builder().build(),
			selectedStatus: '',
		};
	}

	private contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

	private getRequestUrl = (mainString: string, searchText: string, liveblogs?: boolean) => {
		const searchUrl = new URLSearchParams('');

		if (searchText) searchUrl.append('query', searchText);

		if (!liveblogs && this.props.contentLanguage && multiLingualService.checkIfProjectIsMultiLingual(this.props.currentProject.languages)) {
			searchUrl.append('language', this.props.contentLanguage);
		}

		if (this.state.selectedStatus !== '') {
			liveblogs ? searchUrl.append('status', this.state.selectedStatus.toUpperCase()) : searchUrl.append('status', this.state.selectedStatus);
		}

		return `${mainString}&${searchUrl.toString()}`;
	};

	private requests = {
		articles: (page: number, search: string, header: any) =>
			HttpService.get(this.getRequestUrl(`/v2/articles/search?page=${page}`, search), null, header),
		videos: (page: number, search: string, header: any) =>
			HttpService.get(this.getRequestUrl(`/v2/videos/search?page=${page}`, search), null, header),
		galleries: (page: number, search: string, header: any) =>
			HttpService.get(this.getRequestUrl(`/v2/galleries/search?page=${page}`, search), null, header),
		live_blogs: (page: number, search: string, header: any) =>
			HttpService.get(this.getRequestUrl(`/liveblogs?page=${page}&title=${search}`, '', true), null, header),
	};

	onInputChange = (e: any) => {
		this.setState({ ...this.state, inputValue: e.target.value });
		this.requestContent(this.state.currentPage, e.target.value);
	};

	requestContent = async (page: number, search: string) => {
		const header = { Project: this.props.currentProject.domain };
		this.setState((state: State) => {
			return { ...state, isLoading: true };
		});

		if (search.length > 3) {
			let pagination: any;
			let options: any[];

			await this.requests[this.state.searchType](page, search, header)
				.then((response: any) => {
					if (this.state.searchType === `${ContentTypes.LIVE_BLOG}s`) {
						pagination = responseToPaginationModel(response.data.meta);
						options = responseToOptions(response.data.data, relatedTypesMap[this.state.searchType]);
						this.setState((state: State) => {
							return { ...state, options, isLoading: false, currentPage: page, pagination };
						});
					} else {
						pagination = ModelMapper.remapMetaPagination(response.data.meta.pagination);
						options = responseToOptions(response.data.data, relatedTypesMap[this.state.searchType]);
						this.setState(
							(state: State) => {
								return { ...state, options, isLoading: false, currentPage: page, pagination };
							},
							() => {
								if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) && this.props.listContent)
									this.getContentStatistics(options);
							},
						);
					}
				})
				.catch(() => this.resetState());
		} else this.resetState();
	};

	private getContentStatistics = (options: any) => {
		const { searchType } = this.state;
		const ids = extractOptionIds(options);

		HttpService.getContentStatistics(
			this.contentStatisticsConfig.request_headers[0],
			`${this.contentStatisticsConfig.url}?${searchType}=${ids}`,
		)
			.then((response: any) => {
				const optionsWithStatistics = contentStatisticsToOptions(options, response.data);
				this.setState((state: State) => {
					return { ...state, options: optionsWithStatistics };
				});
			})
			.catch((e: any) => e);
	};

	resetState = () => {
		this.setState((state: State) => {
			return { ...state, isLoading: false, options: [], currentPage: 1 };
		});
	};

	onStatusChange = (selectedStatus: string) => {
		this.setState({ ...this.state, selectedStatus }, () => {
			this.state.inputValue.length > 0 && this.requestContent(this.state.currentPage, this.state.inputValue);
		});
	};

	onSelect = (selection: string) => {
		this.setState({ ...this.state, searchType: selection, options: [], currentPage: 1, selectedStatus: '' }, () => {
			this.state.inputValue.length > 0 && this.requestContent(this.state.currentPage, this.state.inputValue);
		});
	};

	onRelatedContentSelect = (optionSelected: any, addItemToTop?: boolean) => {
		if (this.props.onRelatedContentSelect) {
			this.props.onRelatedContentSelect(optionToSearchContentModel(optionSelected), addItemToTop);
		}
	};

	onContentSelect = (optionSelected: any) => {
		if (this.props.onContentSelect) {
			this.props.onContentSelect(optionToSearchContentModel(optionSelected));
		}
		analyticsService.sendEvent('Added from search', 'Sidebar - Related content', optionSelected.type);
	};

	private getStatuses = () => {
		const { searchType } = this.state;
		const { statuses, t } = this.props;

		if (statuses) {
			switch (searchType) {
				case 'articles':
					return statuses[`articleStatuses`];
				case 'videos':
					return statuses[`videoStatuses`];
				case 'galleries':
					return statuses[`galleryStatuses`];
				case 'live_blogs':
					return liveBlogStatusDropdownOptions;

				default:
					return [];
			}
		}
	};

	render() {
		const { searchType, inputValue, isLoading, options, currentPage, pagination, selectedStatus } = this.state;
		const { list, listContent, statuses } = this.props;

		return (
			<>
				<div className='position-relative mb-3'>
					<DebounceInput
						type='text'
						id={`search-content-by-type-${searchType}`}
						value={inputValue}
						onChange={this.onInputChange}
						debounceTimeout={500}
						className='form-control'
						placeholder={this.props.t('search')}
						required
					/>
					{isLoading && (
						<div className='position-absolute h-100 d-flex align-items-center opacity-03' style={{ top: '0', right: '10px' }}>
							<Loader size={5} />
						</div>
					)}
				</div>
				<SearchContentByType t={this.props.t} type={searchType} onSearchTypeSelect={this.onSelect} />
				{statuses && this.getStatuses().length > 0 && (
					<Col col='6' sm='12' md='4'>
						<FormGroup>
							<Label htmlFor='date'>{this.props.t('select_status')}</Label>
							<StatusSelect
								isClearable
								contentType={searchType}
								selectedStatus={selectedStatus}
								statuses={this.getStatuses()}
								onStatusSelect={this.onStatusChange}
								t={this.props.t}
							/>
						</FormGroup>
					</Col>
				)}
				<SearchOptionsContainer
					listContent={listContent}
					listItems={listContent ? (list ? list.items : []) : list ? list : []}
					options={options}
					isLoading={isLoading}
					currentPage={currentPage}
					pagination={pagination}
					value={inputValue}
					t={this.props.t}
					requestContent={this.requestContent}
					onRelatedContentSelect={this.onRelatedContentSelect}
					list={this.props.list}
					toggleFirstLockPositionError={this.props.toggleFirstLockPositionError}
					showAddToTopToggle={this.props.showAddToTopToggle}
					type={searchType}
				/>
				{this.props.listContent && (
					<SuggestedListOptions
						listItems={this.props.list.items ? this.props.list.items : []}
						list={this.props.list}
						t={this.props.t}
						categoryIdFilter={this.props.categoryIdFilter}
						sportsFilter={this.props.sportsFilter}
						onRelatedContentSelect={this.onRelatedContentSelect}
						domain={this.props.currentProject.domain}
						type={searchType}
						toggleFirstLockPositionError={this.props.toggleFirstLockPositionError}
						showAddToTopToggle={this.props.showAddToTopToggle}
						selectedStatus={selectedStatus}
					/>
				)}
			</>
		);
	}
}

export default SearchContentByTypeContainer;
